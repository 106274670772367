import {
    appConfig,
    makeAttached,
    makeDropdown,
    pad
} from "../../../utils.js";

export const addConcession = ({ form, pageItem, store, attachedItems, meta }) => {
    form.setConfig({
        "title": "Add Concession",
        "type": "push",
        "location": "Database/Concessions",
        "fields": [{
            "label": "Number",
            "type": "value",
            value() {
                return `${appConfig.docPrefix}CON-${pad(store.state.counts.database.concession.count)}`
            }
        }, {
            "label": "Comment",
            "name": "Comment",
            "type": "text",
            "divClass": "col-span-2"
        }, {
            "label": "Select Documents",
            "name": "Documents",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Documents.items, "Number", "ID")
        }, {
            "label": "Select Drawings",
            "name": "Drawings",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Drawings.items, "Number", "ID")
        }, {
            "label": "Select Effectivities",
            "name": "Effectivities",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Effectivities.items, "Number", "ID")
        }],
        preSubmitHook({ data }) {
            return {
                "Date": {
                    "Created": new Date().toISOString(),
                    "Cancelled": "N/A",
                    "Signedoff": "N/A"
                },
                "Cancelled": false,
                "Signedoff": false,
                "Author": store.getters.userName,
                "Parent": pageItem.Number,
                "ParentID": pageItem.ID,
                "ParentType": meta.ParentType,
                "Number": `${appConfig.docPrefix}CON-${pad(store.state.counts.database.concession.count)}`,
                "Documents": makeAttached(data.Documents),
                "Drawings": makeAttached(data.Drawings),
                "Effectivities": makeAttached(data.Effectivities)
            }
        },
        postSubmitHook({ db, data, typeRef }) {
            data.ID = typeRef.key;
            db.set(`Database/${meta.ParentType}/${pageItem.ID}/Concessions/${data.ID}`, true);
            db.utils.addAllTo(data, "Database/Documents", "Documents", "Concessions");
            db.utils.addAllTo(data, "Database/Drawings", "Drawings", "Concessions");
            db.utils.addAllTo(data, "Database/Effectivities", "Effectivities", "Concessions");
            db.utils.upCount("counts/database/concession/count");
            db.utils.updateRefs(data, "Database/Refs/Concessions", ["Number", "ID", "Comment", "Signedoff", "Date", "Parent", "ParentID"]);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editConcession = async ({ form, item, attachedItems, store }) => {
    form.setValues({
        "Comment": item.Comment,
        "Documents": Object.keys(item.Documents || {}),
        "Drawings": Object.keys(item.Drawings || {}),
        "Effectivities": Object.keys(item.Effectivities || {})
    });
    form.setConfig({
        "title": "Update Concession",
        "type": "update",
        "location": `Database/Concessions/${item.ID}`,
        "set": true,
        "fields": [{
            "label": "Comment",
            "name": "Comment",
            "type": "text",
            "divClass": "col-span-3"
        }, {
            "label": "Select Documents",
            "name": "Documents",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Documents.items, "Number", "ID")
        }, {
            "label": "Select Drawings",
            "name": "Drawings",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Drawings.items, "Number", "ID")
        }, {
            "label": "Select Effectivities",
            "name": "Effectivities",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Effectivities.items, "Number", "ID")
        }],
        preSubmitHook({ data }) {
            return {
                "Documents": makeAttached(data.Documents),
                "Drawings": makeAttached(data.Drawings),
                "Effectivities": makeAttached(data.Effectivities)
            }
        },
        postSubmitHook({ db, data, typeRef }) {
            data.ID = typeRef.key;
            db.utils.addAllTo(data, "Database/Documents", "Documents", "Concessions");
            db.utils.addAllTo(data, "Database/Drawings", "Drawings", "Concessions");
            db.utils.addAllTo(data, "Database/Effectivities", "Effectivities", "Concessions");
            db.utils.updateRefs(data, "Database/Refs/Concessions", ["Number", "ID", "Comment", "Signedoff", "Date", "Parent", "ParentID", "Title"]);
        }
    });
    await form.getDropdownData();
    
    form.toggle();
};

export const cancelConcession = ({ form, item }) => {
    form.setConfig({
        "title": "Cancel Concession",
        "description": `Are you sure you want to cancel Concession ${item.Number}?`,
        "type": "update",
        "location": `Database/Concessions/${item.ID}`,
        "fields": [],
        preSubmitHook(){
            return {
                "Cancelled": true,
                "Date/Cancelled": new Date().toISOString()
            }
        },
        postSubmitHook({ db }) {
            db.utils.updateRefs(data, "Database/Refs/Concessions", ["Number", "ID", "Comment", "Signedoff", "Date", "Parent", "ParentID"]);
        }
    });
    form.toggle();
};

export const signoffConcession = ({ form, item }) => {
    form.setConfig({
        "title": "Signoff Concession",
        "description": `Are you sure you want to signoff Concession ${item.Number}?`,
        "type": "update",
        "location": `Database/Concessions/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Signedoff": true,
                "Date/Signedoff": new Date().toISOString()
            }
        },
        postSubmitHook({ db }) {
            db.utils.updateRefs(data, "Database/Refs/Concessions", ["Number", "ID", "Comment", "Signedoff", "Date", "Parent", "ParentID"]);
        }
    });
    form.toggle();
};

export const linkConcession = ({ form, pageItem, meta }) => {
    form.setConfig({
        "title": "Link Concession",
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}`,
        "fields": [{
            "label": "Concession",
            "name": "Concession",
            "type": "dropdown",
            "labelKey": "Number",
            "idKey": "ID",
            "dataLocation": "Database/Refs/Concessions"
        }],
        preSubmitHook({liveInputData}) {
            return {
                "Concession": null,
                [`Concessions/${liveInputData.Concession}`]: true
            }
        },
        postSubmitHook({ db, liveInputData }) {
            db.update(`Database/Concessions/${liveInputData.Concession}/${meta.ParentType}/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();

    form.toggle();
};

