<template>
  <div class="fixed top-0 bottom-0 left-0 right-0 z-50 pb-12" style="background: rgba(0, 0, 0, 0.3)"
    v-show="state.open">

    <div class="mx-auto container" style="max-width: 1200px">
      <div class="
          bg-white
          rounded
          shadow
          p-2
          border border-gray-100
          opacity-100
          mt-12
           z-100
        " id="_db_help_container">
        <div class="flex flex-row items-center">
          <p class="p-2 text-text font-medium flex-grow text-primary text-sm">
            Database Help
          </p>
          <IconButton icon="times" @click="toggle" class="cancel" />
        </div>

        <!-- this is the chat window that displays the messages -->
        <div class="p-2 max-h-96 overflow-y-auto">
          <!-- display initial message -->
          <div class="mb-2">
            <div class="text-left">
              <p class="
                  bg-gray-100
                  text-gray-800
                  p-2
                  rounded-lg
                  inline-block
                ">
                {{ initialMessage }}
              </p>
            </div>
          </div>
          <div v-for="message in messages" :key="message.id" class="mb-2">
            <div :class="{
          'text-right': message.role === 'user',
          'text-left': message.role === 'bot',
              }">
              <p :class="{
            'bg-blue-100 text-blue-800 p-2 rounded-lg inline-block': message.role === 'user',
            'bg-gray-100 text-gray-800 p-2 rounded-lg inline-block': message.role === 'bot',
          }">
                <VueMarkdown :source="message.text" />
              </p>
            </div>
          </div>
        </div>
        <Form @submit="submit" id="_db_help_form" v-if="!state.loading">
          <div class="p-2">
            <Input ref="searchBox" placeholder="Search" name="search" @value="searchText" id="_db_help_input" />
          </div>
        </Form>
        <Loading v-if="state.loading" />
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  computed,
  reactive,
  defineExpose,
  watch,
  getCurrentInstance,
} from "vue";
import { Input, Loading, IconButton, Form } from "@comp";
import { get, merge, sortBy } from "lodash";
import serialize from "form-serialize";
import { db } from "../db.js";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { time } from "ag-charts-community";
import VueMarkdown from 'vue-markdown-render'


const store = useStore();
const router = useRouter();

let state = reactive({
  open: false,
  loading: false,
  submitting: false,
  searchables: [],
  results: [],
});

const searchBox = ref(null);

const toggle = () => {
  state.open = !state.open;
  let inputContainer = document.getElementById("_db_search_container");
  let inputs = inputContainer.getElementsByTagName("INPUT");
  if (state.open) {
    setTimeout(() => {
      console.log(searchBox.value.inputElement);
      
      searchBox.value.inputElement.focus();
    }, 100);
  } else {
    searchBox.value.inputElement.value = "";
  }
};

const searchText = ref("");

const conversationID = ref("");

const initialMessage = 'Hi! I\'m the Database help agent. I can help with your queries on how to perform actions in this application.';

const messages = computed(() => {
  if(!conversationID.value) return [];  
  if(!store.state.helpChat) return [];
  else return Object.values(store.state.helpChat.messages);
})

watch(messages, (m) => {
  // get last message and check if it is from the bot
  if (m.length > 0) {
    const lastMessage = m[m.length - 1];
    if(lastMessage.messageReturned === false) state.loading = true;
    else state.loading = false;
  }
});

const submit = async () => {
  state.loading = true;
  searchText.value = '';
  let form = document.getElementById("_db_help_form");
  let data = serialize(form, { hash: true });
  
 const newMessage = {
    text: data.search,
    role: 'user',
    timestamp: new Date().toISOString(),
    messageReturned: false
  };
  
  if (conversationID.value === '') {
    const conversation = await db.push("help-chat", {
        timestamp: new Date().toISOString(),
    });
    conversationID.value = conversation.key;
    await db.watch(`help-chat/${conversationID.value}`, store, 'helpChat');
  }
  await db.push(`help-chat/${conversationID.value}/messages`, newMessage);
};

defineExpose({
  toggle,
});
</script>
